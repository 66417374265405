<template>
  <b-container fluid class="calendar-event-slots-form">
    <b-row v-if="!computedTarget">
      <b-col>
         <members-select
          v-if="targetType === 'member'"
          v-model="selectedTarget"
          close-on-select
          :clearable="false"
          :fetch-data="fetchData"
          @input="$emit('update:target', $event)"
          >
            <template #option="option">
              <b-avatar
                size="sm"
                :src="option.avatarURL"
              />
              <span class="ml-50 align-middle">{{ option.name }} {{ option.surname }}</span>
            </template>

            <template #selected-option="option">
              <b-avatar
                size="sm"
                class="border border-white"
                :src="option.avatarURL"
              />
              <span class="ml-50 align-middle">{{ option.name }} {{ option.surname }}</span>
            </template>
          </members-select>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <b-calendar
          v-model="selectedYMD"
          :min="today"
          :date-disabled-fn="dateDisabled"
          :locale="locale"
          @context="handleContextChange"
        />
      </b-col>
      <b-col>
        <div v-if="isLoading">
          <b-spinner class="d-block mx-auto mt-3 mb-1" type="grow" />
          <p class="text-center text-muted">
            {{ $t('calendar.request-time-slot.loading-message') }}
          </p>
        </div>
        <div v-else-if="!selectedYMD" class="text-center mt-4">
          {{ $t('calendar.request-time-slot.select-a-date') }}
        </div>
        <div v-else class="mt-0">
          <h5>
            {{ $t('calendar.request-time-slot.available-slots') }}  ({{ $t('events.event-details.local-time') }})
          </h5>
          <b-list-group class="my-2">
            <b-list-group-item
              v-for="({ startDate, endDate, blocked }, index) in availableSlots[selectedYMD]"
              :key="index"
              button
              :disabled="isSaving || isInThePast(startDate) || isSavingUserOcupation[index]"
              :active="isSlotActive(index) || blocked"
              :class="{ 'removable-slot': isMyself }"
              @click="handleSelectSlot(index)"
            >
              {{ $moment.unix(startDate).format('HH:mm') }} - {{ $moment.unix(endDate).format('HH:mm') }}
              <small v-if="isMyself && !isInThePast(startDate)" class="float-right mark-as">
                <b-spinner v-if="isSavingUserOcupation[index]" type="grow" small />
                <template v-else>
                  [mark as {{ blocked ? 'available' : 'unavailable' }}]
                </template>
              </small>
            </b-list-group-item>
          </b-list-group>
          <validation-observer
            v-if="!isMyself && selectedSlotIndex !== null"
            #default="{ handleSubmit }"
            ref="refFormObserver"
          >
            <!-- Form -->
            <b-form
              class="pb-2"
              @submit.prevent="handleSubmit(onSubmit)"
            >
              <!-- Title -->
              <validation-provider
                #default="validationContext"
                :name="$t('calendar.meeting-form.title.name')"
                rules="max:255"
              >
                <b-form-group
                  :label="$t('calendar.meeting-form.title.label')"
                  label-for="profile-title"
                >
                  <b-form-input
                    id="profile-title"
                    v-model="eventForm.title"
                    :state="getValidationState(validationContext)"
                    trim
                    :placeholder="$t('calendar.meeting-form.title.placeholder')"
                    :disabled="isSaving"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <!-- Description -->
              <validation-provider
                #default="validationContext"
                :name="$t('calendar.meeting-form.description.name')"
                rules="max:500"
              >
                <b-form-group
                  :label="$t('calendar.meeting-form.description.label')"
                  label-for="profile-description"
                >
                  <plain-editor
                    id="profile-description"
                    v-model="eventForm.description"
                    class="form-control text-editor"
                    :placeholder="$t('calendar.meeting-form.description.placeholder')"
                    :disabled="isSaving"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
              <div class="d-flex mt-2">
                <b-button
                  variant="primary"
                  class="mr-2"
                  type="submit"
                  :disabled="isSaving"
                >
                  {{ $t('action.confirm') }}
                </b-button>
              </div>
            </b-form>
          </validation-observer>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import PlainEditor from '@core/components/editor/PlainEditor.vue';
import MembersSelect from '@/views/apps/member/components/MembersSelect.vue';
import Service from '@/config/service-identifiers';

import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

import {
  required,
  max,
} from '@validations';
import { getValidationState } from '@core/comp-functions/forms/form-validation';

import { EVENTS_ACTIONS } from '@/views/apps/events/constants/events-store-constants';

export default {
  name: 'CalendarEventSlotsForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    PlainEditor,
    MembersSelect,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    target: {
      type: Object,
      default: null,
    },
    targetType: {
      type: String,
      required: true,
      validator(targetType) {
        return ['member', 'organization'].includes(targetType);
      },
    },
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,

      selectedTarget: null,
      selectedYMD: null,
      selectedSlotIndex: null,
      activeYMD: null,

      availableSlots: null,
      isSavingUserOcupation: {},

      eventForm: {
        title: '',
        description: '',
      },

      required,
      max,
    };
  },
  computed: {
    today() {
      return new Date();
    },
    locale() {
      return this.$store.getters.currentLocale;
    },
    computedTarget() {
      return this.selectedTarget || this.target;
    },
    isMyself() {
      return this.targetType === 'member' && this.computedTarget?.userKey === this.$store.getters.loggedUser.key;
    },
  },
  methods: {
    async fetchData(page, searchString = '') {
      if (!searchString) {
        return this.$store.dispatch('getItemsNode', {
          itemType: 'communityMembers',
          page,
          perPage: 16,
        });
      }
      const response = await this.$store.$service[Service.BackendClient].get('communityMembers', {
        params: {
          communityKey: this.$store.getters.currentCollective.key,
          searchString,
          page,
          perPage: 16,
        },
      });

      return response.data;
    },
    dateDisabled(ymd) {
      return !this.availableSlots || !this.availableSlots[ymd] || this.availableSlots[ymd].length === 0;
    },
    isInThePast(startDate) {
      return this.$moment.unix(startDate).isBefore(new Date());
    },
    handleContextChange({ activeYMD }) {
      if (!this.activeYMD || !this.$moment(activeYMD).isSame(this.activeYMD, 'month')) {
        this.activeYMD = activeYMD;
        this.loadAvailableSlots(activeYMD);
      }
    },
    handleSelectSlot(index) {
      if (this.isMyself) {
        this.toggleUserOcupation(index);
      } else {
        this.selectedSlotIndex = index;
      }
    },
    isSlotActive(index) {
      return this.selectedSlotIndex === index;
    },
    async loadAvailableSlots(activeYMD) {
      this.isLoading = true;
      this.selectedSlotIndex = null;
      this.isSavingUserOcupation = {};

      const since = this.$moment(activeYMD).startOf('month').startOf('week').unix();
      const until = this.$moment(activeYMD).endOf('month').endOf('week').unix();
      const response = await this.$store.$service[Service.BackendClient].get('timeslots', {
        params: {
          since,
          until,
          communityKey: this.$store.getters.currentCollective.key,
          ...(
            this.targetType === 'member'
              ? { userKey: this.computedTarget.userKey }
              : { organizationKey: this.computedTarget.key }
          ),
        },
      });

      this.availableSlots = response.data.days.reduce((availableSlots, { date, slots }) => ({
        ...availableSlots,
        [date]: slots,
      }), {});

      if (this.isMyself) {
        await this.loadUserOcupation(since, until);
      }
      this.isLoading = false;
    },
    async loadUserOcupation(since, until) {
      const response = await this.$store.$service[Service.BackendClient].get('userOcupation', {
        params: {
          since,
          until,
        },
      });

      response.data.days.forEach(({ date, slots }) => {
        slots.forEach(({ startDate, endDate }) => {
          this.availableSlots[date].push({ startDate, endDate, blocked: true });
        });
        this.availableSlots[date].sort(({ startDate: startDate1 }, { startDate: startDate2 }) => (
          startDate1 - startDate2
        ));
      });
    },
    async toggleUserOcupation(index) {
      const timeslot = this.availableSlots[this.selectedYMD][index];
      this.$set(timeslot, 'blocked', !timeslot.blocked);
      this.$set(this.isSavingUserOcupation, index, true);
      try {
        await this.$store.$service[Service.BackendClient].post(
          timeslot.blocked ? 'addUserOcupation' : 'removeUserOcupation',
          {
            startDate: timeslot.startDate,
            endDate: timeslot.endDate,
          },
        );
      } catch {
        this.$set(timeslot, 'blocked', !timeslot.blocked);
        this.notifyError(this.$t('error-message.general-error'));
      }
      this.$set(this.isSavingUserOcupation, index, false);
    },
    async onSubmit() {
      this.isSaving = true;
      const eventData = {
        title: this.eventForm.title,
        start: this.availableSlots[this.selectedYMD][this.selectedSlotIndex].startDate * 1000,
        end: this.availableSlots[this.selectedYMD][this.selectedSlotIndex].endDate * 1000,
        extendedProps: {
          description: this.eventForm.description,
          location: null,
          allDay: false,
          guests: (this.targetType === 'member' ? [this.computedTarget] : []),
        },
      };

      try {
        await this.$store.dispatch(
          EVENTS_ACTIONS.createMeeting,
          {
            locale: this.locale,
            ...(
              this.targetType === 'member'
                ? { collectiveKey: this.$store.getters.currentCollective.key }
                : { organizationKey: this.target.key }
            ),
            event: eventData,
            showCreated: true,
          },
        );
        this.$emit('event-added', eventData);
        this.notifySuccess(this.$t('calendar.messages.event-added.success'));
      } catch {
        this.notifyError(this.$t('calendar.messages.event-added.error'));
      }
    },
    getValidationState,
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-include';

.calendar-event-slots-form {
  min-height: 50vh;
}
.text-editor::v-deep {
  height: auto;
  .ql-editor {
    min-height: 96px;
  }
}

.removable-slot {
  &.active {
    border-color: $danger;
    background-color: $danger;
    &:focus,
    &:hover {
      border-color: darken($danger, 15%);
      background-color: darken($danger, 15%);
    }
  }
  &:hover {
    .mark-as {
      font-weight: $font-weight-bold;
    }
  }
}
</style>
