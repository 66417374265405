<template>
  <b-modal
    :visible="visible"
    footer-class="d-none"
    size="lg"
    @change="$emit('update:visible', $event)"
    @show="handleShow"
    @hidden="$emit('update:edit', false)"
  >
    <template #modal-title>
      {{
        computedTarget
          ? $t('calendar.request-time-slot.title', [targetName])
          : $t('calendar.request-time-slot.no-member-title')
      }}
    </template>
    <b-spinner
      v-if="loading"
      type="grow"
      small
      class="mx-auto d-block"
    />
    <calendar-event-slots-form
      v-else
      :target="target"
      :target-type="targetType"
      @event-added="$emit('update:visible', false)"
      @update:target="selectedTarget = $event"
    />
  </b-modal>
</template>

<script>
import { BModal, BSpinner } from 'bootstrap-vue';
import CalendarEventSlotsForm from '../calendar-event-slots-form/CalendarEventSlotsForm.vue';

export default {
  name: 'CalendarEventSlotsFormModal',
  components: {
    BModal,
    BSpinner,
    CalendarEventSlotsForm,
  },
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    loading: Boolean,
    target: {
      type: Object,
      default: null,
    },
    targetType: {
      type: String,
      required: true,
      validator(targetType) {
        return ['member', 'organization'].includes(targetType);
      },
    },
  },
  data() {
    return {
      selectedTarget: null,
    };
  },
  computed: {
    isExistingEvent() {
      return !!this.event.id;
    },
    eventLocation() {
      return { name: 'event', params: { id: this.event.id, communityId: this.$route.params.communityId } };
    },
    computedTarget() {
      return this.selectedTarget || this.target;
    },
    targetName() {
      if (!this.computedTarget) {
        return '';
      }
      return this.targetType === 'member'
        ? `${this.computedTarget.name} ${this.computedTarget.surname}`
        : this.computedTarget.name;
    },
    isMyself() {
      return this.targetType === 'member' && this.computedTarget?.userKey === this.$store.getters.loggedUser.key;
    },
    modalTitle() {
      if (this.isMyself) {
        return this.$t('calendar.request-time-slot.edit-timeslots-title');
      }

      return this.computedTarget
        ? this.$t('calendar.request-time-slot.title', [this.targetName])
        : this.$t('calendar.request-time-slot.no-member-title');
    },
  },
  methods: {
    handleShow($event) {
      this.selectedTarget = null;
      this.$emit('show', $event);
    },
  },
};
</script>

<style lang="scss" scoped>
  .event-link {
    margin-left: 0.5rem;
    display: inline-flex;
    align-items: top;
  }
</style>
